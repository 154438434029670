import $ from 'jquery';

class Menu {
  constructor() {
    this.$menu = $('.js-menu');
    this.$menuOpenBtn = $('.js-menu-open');
    this.$menuCloseBtn = $('.js-menu-close');
    if (this.$menu.length > 0) {
      this.addEvent();
    }
  }

  addEvent() {
    this.$menuOpenBtn.on('click', (evt) => {
      this.$menu.addClass('is-open');
    });
    this.$menuCloseBtn.on('click', (evt) => {
      this.$menu.removeClass('is-open');
    });
    // 範囲外クリック判定
    $(document).on('click', (evt) => {
      if(!$(evt.target).closest('.js-menu').length && !$(evt.target).closest('.js-menu-open').length) {
        this.$menu.removeClass('is-open');
      }
    });
  }
}

export default Menu;
