import $ from 'jquery';

import '../styles/index.scss'; // webpackでコンパイルするため必須. 実際には.jsには展開されない.

import TopSlider from './components/TopSlider';
import Menu from './components/Menu';
import Modal from './components/Modal';

window.onload = function() {
  // 初期ローディング
  if($('.js-loading').length > 0) {
    $('.js-loading').addClass('is-loaded');
    // スライダー開始
    new TopSlider();
    setTimeout(function() {
      $('.js-heroSlider').slick('slickPlay');
    },1500);
  }
  // トップページのコピー
  if($('.js-heroCopy01').length > 0) {
    $('.js-heroCopy01').fadeIn(2000);
    $('.js-heroCopy01').delay(11000).fadeOut(1000);
    $('.js-heroCopy02').delay(15000).fadeIn(2000);
  }
}

$(document).ready(() => {
  new Menu();
  new Modal();
});