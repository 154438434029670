import $ from 'jquery';
import 'slick-carousel';

class TopSlider {
  constructor() {
    this.$slider = $('.js-heroSlider');
    if (this.$slider.length > 0) {
      this.init();
    }
  }

  init() {
    this.setSlick();
    this.addEvent();
  }

  setSlick() {
    this.$slider.slick({
      lazyLoad: 'progressive',
      fade: true,
      speed: 1500,
      autoplaySpeed: 2500,
      arrows: false,
      pauseOnHover: false,
      pauseOnFocus: false,
      accessibility: false,
      swipe: false
    });
  }

  addEvent() {
    // 1周したらslickを止める
    $('.js-heroSlider').on('afterChange', function(evt, slick, currentSlide) {
      var slideCount = slick.slideCount - 1;
      if(slideCount === currentSlide) {
        $(this).slick('slickPause');
      }
    });
  }
}

export default TopSlider;
