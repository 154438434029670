import $ from 'jquery';

class Modal {
  constructor() {
    this.$modal = $('.js-modal');
    this.$modalOpenBtn = $('.js-modal-open');
    this.$modalCloseBtn = $('.js-modal-close');
    if (this.$modal.length > 0) {
      this.addEvent();
    }
  }

  addEvent() {
    var scrollPosition;
    this.$modalOpenBtn.on('click', (evt) => {
      var $targetItem = $(evt.target).parent(this.$modalOpenBtn);
      scrollPosition = $(window).scrollTop();
      $targetItem.next('.js-modal').addClass('is-open');
      $('body').addClass('is-modal-open');
    });
    this.$modalCloseBtn.on('click', (evt) => {
      this.$modal.removeClass('is-open');
      $('body').removeClass('is-modal-open');
      $(window).scrollTop(scrollPosition);
    });
  }
}

export default Modal;
